import React, { useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./AboutCompany.css";

export default function AboutCompany(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const SCREEN_CONSTSANTS = {
    description:
      "„A Varkos Kivitelező Kft. olyan, mint egy család”. Történetünk néhány évvel ezelőtt két barát összefogásával indult, s mára már valóban családként működünk, hiszen együtt dolgozik apa, fia, testvérek és gyerekkori barátok.",
    description_2: "Összefogásunk legfőbb célja a gördülékeny, minőségi munkavégzés, amelyet csapatunk szakképzet, több évtizedes múlttal rendelkező tagjai garantálják.",
    description_3: "Nálunk nincsenek rejtett költségek, sem „feketén” elkért előlegek, cégünk minden munkájára teljeskörű garanciát vállal. Legyen szó a legkisebb lejavítástól, egészen a generálkivitelezésig, keressenek minket bizalommal.",
    description_4: "Köszönettel,",
    description_5: "Varkos Kivitelező csapata",
    highlights: {
      bullets: [
        "Kőműves munkák",
        "Ács-tetőfedés",
        "Térkövezés",
        "Hideg-meleg burkolás",
        "Szobafestés-mázolás",
        "Víz-, hő- és hangszigetelés"
      ],
      heading: "Főbb szakterületeink:",
    },
  };
  const renderHighlight = () => {
    return SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };

  return (
    <div
      className="about-company-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="about-company-parent">
        <ScreenHeading title={"Cégről"} subHeading={"Miért minket válasszon?"} />
        <div className="about-company-card">
          <div className="about-company-profile"></div>
          <div className="about-company-details">
            <span className="about-company-description">
              {SCREEN_CONSTSANTS.description}
            </span>
            <br/>
            <br/>
            <span className="about-company-description">
              {SCREEN_CONSTSANTS.description_2}
            </span>
            <br/>
            <br/>
            <span className="about-company-description">
              {SCREEN_CONSTSANTS.description_3}
            </span>
            <br/>
            <br/>
            <span className="about-company-description">
              {SCREEN_CONSTSANTS.description_4}
            </span>
            <br/>
            <span className="about-company-description">
              {SCREEN_CONSTSANTS.description_5}
            </span>
            <div className="about-company-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTSANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>
            <div className="about-company-options">
              <button
                className="btn primary-btn"
                onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
              >
                {" "}
                Kérjen ajánlatot{" "}
              </button>
              
              <a href='varkos_price_list.pdf' className="price-list" target="_blank">
                <button className="btn highlighted-btn">Árlista</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
