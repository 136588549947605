import React, { useState, useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";

const Resume = (props) => {
  /* STATES */
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  const resumeBullets = [
    {label: "Kőműves munkák", logoSrc: "komuves.svg"},
    {label: "Ács-tetőfedés", logoSrc: "teto.svg"},
    {label: "Térkövezés", logoSrc: "terko.svg"},
    {label: "Hideg-meleg burkolás", logoSrc: "burkolas.svg"},
    {label: "Szobafestés-mázolás", logoSrc: "festes.svg"},
    {label: "Víz-, hő- és hangszigetelés", logoSrc: "szigeteles.svg"}
  ];

  const projectsDetails = [
      {
        title: "Betonozás, vasszerezlés ",
        duration: {fromDate: "2020", toDate: "2021"},
        description: "Az épület alapja a beton, egy jó betonnak pedig a vasszerelés. Statikai szerep szempontjából kulcsfontosságú a megfelelő kivitelezés, legyen szó alap, fődém vagy támfalról.",
        subHeading: "Festés, Burkolás"
      },
      {
        title: "Falazás",
        duration: {fromDate: "2020", toDate: "2021"},
        description: "Mivel a falak adják épületeink gerincét, közel sem mindegy annak kivitelezése. Bármely hanyagul elkészített falrész nem csak a további munkafolyamatokat (vakolás, glettelés) nehezíti meg, hanem könnyen statikai kockázatot jelent. Az eddigi több ezer négyzetméteres tapasztalattal a hátunk mögött munkánk mellett garanciával biztosítjuk ügyfeleink elégedettségét.",
        subHeading: "Festés, Burkolás"
      },
      {
        title: "Vakolás",
        duration: {fromDate: "2020", toDate: "2021"},
        description: "Régi és új házaknál egyaránt fontos szerep jut a vakolásnak, hiszen a szép, függőlegesen sík falak alapját adják.",
        subHeading: "Festés, Burkolás"
      },
  ];

  const projectsDetails_Festes = [
    {
      title: "Glettelés",
      description: "A glettelés kulcsfontosságú az esztétikus, szépen kifestett helyiséghez. Minőségi glettelés, precíz munkavégzés.",
    },
    {
      title: "Festés",
      description: "Bízza ránk megálmodott színvilágát, és mi megvalósítjuk.",
    },
    {
      title: "Tapétázás",
      description: "Gondos kivitelezést igényel a tapétázás, hiszen senki sem szereti az illesztéscsíkokat, törést. Színes, mintás, egyedi, nem jelent akadályt. ",

    },
  ];

  const projectsDetails_Szigeteles = [
    {
      title: "Vízszigetelés",
      description: "A vízszigetelés lényege, hogy meggátolja a nedvesség káros hatásai épületszerkezetekben, elemekben. Rosszul elvégzett szigetelés jelentős problémákhoz vezethet.",
    },
    {
      title: "Hőszigetelés",
      description: "Egy épületnek fontos tartozéka a megfelelő szigetelés, hiszen az biztosítja otthonunk melegét, pontosabban annak megtartását. Legyen szó homlokzati, födém, vagy szarufaszigetelésről, megfelelő anyagválasztás és kivitelezés mellett jelentősen csökkentheti költségeit.",
    },
    {
      title: "Hangszigetelés",
      description: "Ha túl hangos a szomszéd, utcáról beszűrődő, vagy egyéb zajok nem szabad feladni az Ön nyugalmát. Szerencsére napjainkban számos hangszigetelő technika létezik, melyeknek kivitelezését vállaljuk.",

    },
  ];

  const resumeDetails = [
    
    <div className="resume-screen-container" key="komuves">
      {projectsDetails.map((projectsDetails, index) => (
        <ResumeHeading
          key={index}
          heading={projectsDetails.title}
          description={projectsDetails.description}
        />
      ))}
    </div>,

    <div className="resume-screen-container" key="acs">
        <ResumeHeading
            heading={"Tetőfedés"}
            description={"Legyen szó lakóépületről, melléképület vagy teraszról, valamennyinél elengedhetetlen állagmegóvás szempontjából a hiba nélküli tetőkivitelezés. Éppen ezért nagy szakértelmet és odafigyelést igényel az időtálló szerkezet, és fedés elkészítése, melyekre cégünk -mint a többi munkájára- garanciát biztosít."}
        />
        <ResumeHeading
            heading={"Kocsibeálló, szaletli, pavilon készítés"}
            description={"A szaletli nem csak praktikus, hanem igényes megoldást jelent, amellyel nem csak udvara megjelenését, hanem a kerti partik hangulatát egyaránt feldobhatja. Válasszon minket és segítünk megtervezni, kivitelezni az Ön igényeinek megfelelően."}
        />
    </div>,

    <div className='resume-screen-container' key='terko'>
        <ResumeHeading
            heading={"Térkövezés"}
            subHeading={"Dobja fel az udvarát új arculattal"}
        />
        <div className='experience-description'>
            <span className='resume-description-text'>
        
              Elege lett az esőzések utáni sárból, kocsi elakadásból? 
              Szeretnének egy helyet ahol nyáron medence van, és az év többi napján is sármentes? 
              Esetleg csak felszeretné dobni az udvarát?
            </span>
        </div>
        <div className='experience-description'>
            <span className='resume-description-text'>
            - Megfelelő rétegrend kialakítással, gondos kivitelezéssel tartósan biztosít igényes megjelenést udvara számára.
            </span>
        </div>
      </div>,
          <div className='resume-screen-container' key='burkolas'>
          <ResumeHeading
              heading={"Hideg-meleg burkolás"}
              subHeading={"Dobja fel otthona tereit"}
          />
          <div className='experience-description'>
              <span className='resume-description-text'>
                Ahogyan a térkő az udvarát, úgy a megfelelően kiválasztott burkolat az otthonát dobja fel.
              </span>
          </div>
          <div className='experience-description'>
              <span className='resume-description-text'>
              - Természetesen mind esztétika, mind pedig időtállóság szempontjából elengedhetetlen a megfelelő szakértelem és tapasztalat.
              </span>
              <br/>
              <span className='resume-description-text'>
              - Amennyiben minket választ segítünk az ízlésének megfelelően szebbé varázsolni otthonát.
              </span>
          </div>
        </div>,

        <div className="resume-screen-container" key="festes">
        {projectsDetails_Festes.map((projectsDetails, index) => (
          <ResumeHeading
            key={index}
            heading={projectsDetails.title}
            description={projectsDetails.description}
          />
        ))}
        </div>,

        <div className="resume-screen-container" key="szigeteles">
        {projectsDetails_Szigeteles.map((projectsDetails, index) => (
          <ResumeHeading
            key={index}
            heading={projectsDetails.title}
            description={projectsDetails.description}
          />
        ))}
        </div>
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../assets/Resume/${bullet.logoSrc}`).default}
          alt="B"
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={"Szakterületeink"} subHeading={""} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
