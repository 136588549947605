import Home from "../CompanyContainer/Home/Home";
import AboutCompany from "../CompanyContainer/AboutCompany/AboutCompany";
import Resume from "../CompanyContainer/Resume/Resume";
import Testimonial from "../CompanyContainer/Testimonial/Testimonial";
import ContactCompany from "../CompanyContainer/ContactCompany/ContactCompany";
import OthersAboutUs from "../CompanyContainer/OthersAboutUs/OthersAboutUs";

export const TOTAL_SCREENS = [
  {
    screen_name: "Főoldal",
    component: Home,
  },
  {
    screen_name: "Cégről",
    component: AboutCompany,
  },
  {
    screen_name: "Szakterületeink",
    component: Resume,
  },
  {
    screen_name: "Referenciák",
    component: Testimonial,
  },
  {
    screen_name: "Média",
    component: OthersAboutUs,
  },
  {
    screen_name: "Kapcsolat",
    component: ContactCompany,
  },
];

export const GET_SCREEN_INDEX = (screen_name) => {
  if (!screen_name) return -1;

  for (let i = 0; i < TOTAL_SCREENS.length; i++) {
    if (TOTAL_SCREENS[i].screen_name === screen_name) return i;
  }

  return -1;
};
