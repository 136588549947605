import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Testimonial.css";

import first from "../../../src/images/1.jpg";
import sec from "../../../src/images/2.jpg";
import third from "../../../src/images/3.jpg";
import fourth from "../../../src/images/4.jpg";
import fifth from "../../../src/images/5.jpg";
import seventh from "../../../src/images/7.jpg";
import eighth from "../../../src/images/8.jpg";
import nineth from "../../../src/images/9.jpg";

export default function Testimonial(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const options = {
    loop: true,
    margin: 0,
    nav: true,
    animateIn: "bounceInRight",
    animateOut: "bounceOutRight",
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div>
      <ScreenHeading
        title={"Referenciák"}
        subHeading={"Korábbi munkáink:"}
      />
      <section className="testimonial-section fade-in" id={props.id || ""}>
        <div className="container">
          <div className="row">
            <OwlCarousel
              className="owl-carousel"
              id="testimonial-carousel"
              {...options}
            >
              <div className="col-lg-12">
                <div className="testi-item">
                  <img src={first} alt="Not found" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testi-item">
                  <img src={sec} alt="Not found" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testi-item">
                  <img src={third} alt="Not found" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testi-item">
                  <img src={fourth} alt="Not found" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testi-item">
                  <img src={fifth} alt="Not found" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testi-item">
                  <img src={seventh} alt="Not found" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testi-item">
                  <img src={eighth} alt="Not found" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="testi-item">
                  <img src={nineth} alt="Not found" />
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
    </div>
  );
}
