import React, { useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./OthersAboutUs.css";


export default function OthersAboutUs(props) {
    let fadeInScreenHandler = (screen) => {
        if (screen.fadeInScreen !== props.id) return;
        Animations.animations.fadeInScreen(props.id);
      };
      const fadeInSubscription =
        ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

    return (
        <div       
        className="othersaboutus-container fade-in"
        id={props.id || ""}>
            <ScreenHeading subHeading={"Interjú(k) velünk:"} title={"Média"} />
            <div className="othersaboutus-central-form">
                <div className="othersaboutus-heading">
                    <a href="https://www.daibau.hu/cikk/263/lakasfelujitas_5_legfontosabb_lepes#varkos-kivitelezo-kft" target="_blank" >
                        <span>{"Lakásfelújítás: 5 legfontosabb lépés - Varkos Kivitelező Kft. szakembereinek tanácsaival részlet:"}</span> 
                    </a>
                </div>
                <div className="othersaboutus-central-sub-form">
                    <div className="othersaboutus-sub-heading">
                        <i><span>1. Hogyan történik Önöknél egy teljeskörű felújítás? Mire kell odafigyelnünk?</span></i>
                    </div>
                    <span className="othersaboutus-description">
                        Általában úgy kezdődik megkeresnek minket xy munkával, amire írunk egy előzetes árajánlatot legtöbbször sávozva, négyzetméter alapján, valamint egy lehetséges kezdési időpontot. Amennyiben az ügyfél reálisnak találja az árat elmegyünk személyesen felmérni a munkát, illetve leegyeztetni a részleteket. Ezután adunk egy végleges árat, ha az ügyfél szeretné küldünk szerződést. Az anyagok megvásárlása opcionális, van aki szereti maga megvenni, van aki inkább ránk bízza.
                    </span>
                    <span className="othersaboutus-description">
                        Felújításokhoz úgy állunk hozzá, mintha a sajátunkat csinálnánk. Igyekszünk folyamatos rendet, átláthatóságot tartani, amennyire az aktuális munkánk engedi igazodni a lakókörnyezethez (pl megkérnek mikor ne zajoskodjunk, kisgyerek alszik stb), anyagok minőségét megőrizni. Fontosnak tartjuk a folyamatos egyeztetést a tulajdonosokkal, ha egy problémát feltárunk, azonnal jelezni. Szeretünk ötleteket és javaslatokat is megosztani a tulajdonosokkal, hiszen ők nem feltétlen látják mi hogyan fog kinézni. Rengetegen életükben elsőnek kerülnek ilyen szituációba, például babát várnak, éppen elköltöznek szülőktől stb., így fontosnak tartjuk megosztani velük a mi tapasztalatainkat, véleményünket.  Utólag soha se számítunk fel árat, minden várható pluszköltséget előre közlünk.
                    </span>

                    <div className="othersaboutus-sub-heading">
                        <i><span>2. Jobb e, ha minden munkára választunk külön mestert, vagy az egész felújítást egy cég végzi el?</span></i>
                    </div>
                    <span className="othersaboutus-description">
                        Ez attól függ. Alapvetően jobb az, ha kevesebb kézben van minden. Hiszen pl egy teljes körű felújítás számos szakmát érinthet. Villanyszerelés, víz-gáz-fűtés, kőműves, festő, burkoló stb. Ezek a szakmák egymásra épülnek így egymástól függenek a szakemberek, így minél több embertől függ a gördülékeny haladás annál nagyobb a kockázat, nem jelenik meg, eltűnik, történik vele valami stb. Nyilván a központosításnál alapfeltétel, hogy az adott szakmákban ténylegesen otthon legyen a vállalkozó. A mi cégünk kőműves, ács, festő, burkolás és szigetelési munkákat vállal több évtizedes szakmai háttérrel, szaktudást igazoló bizonyítvánnyal.
                    </span>

                    <div className="othersaboutus-sub-heading">
                        <i><span>3. Ügyfeleink mért válasszák az Önök cégét?</span></i>
                    </div>
                    <span className="othersaboutus-description">
                        A mi cégünk olyan, mint egy család. Szó szerint hiszen családok generációi dolgoznak együtt, több évtizedes tapasztalattal (valaki 30 éve a pályán van). Éppen ezért nálunk nagyon szoros és megbízható az egyes szakmák közötti kölcsönös együttműködés. Az ügyfelekhez való hozzáállásunk barátságos, célunk legalább kölcsönös szimpátiát kialakítani, hiszen az mindenkinek kedvező hosszútávon. Maximális minőségre törekszünk, abban hiszünk, ez a legjobb marketing.
                    </span>

                    <br/>
                    <i><span className="othersaboutus-description">Varga Krisztián ügyvezető</span></i>
                    <i><span className="othersaboutus-description">Czinkos Tibor tulajdonos</span></i>
                </div>
            </div>

        </div>
    );
}