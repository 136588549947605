import React from "react";
import Typical from "react-typical";
import ScrollService from "../../../utilities/ScrollService";
import "./Profile.css";

export default function Profile() {
  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
            <div className="colz-icon">
              <a href="#">
                <i  />
              </a>
            </div>
          </div>
          <div className="profile-details-name">
            <span className="primary-text">
              {" "}
              Üdvözöljük, Mi vagyunk a <span className="highlighted-text">Varkos Kivitelező Kft.</span>
            </span>
          </div>
          <div className="profile-details-role">
            <span className="primary-text">
              {" "}
              <h1>
                <Typical
                  loop={Infinity}
                  steps={[
                    "Építkezés", 1000,
                    "Bövítés", 1000,
                    "Felújítás", 1000
                  ]}
                />
              </h1>
            </span>
            <span className="profile-role-tagline">
            Keressen minket bizalommal, biztosan találunk megoldást a problémára.
            </span>
          </div>

          <div className="profile-options">
            <button className="btn primary-btn"
            onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
            > Kérjen Ajánlatot </button>

            <a href='varkos_price_list.pdf' target="_blank">
              <button className="btn highlighted-btn">Árlista</button>
            </a>
          </div>
        </div>
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
}
